// Emit “font-loaded” event so expandable components know to recalc their height
document.fonts.ready.then(() => {
  let d = document.documentElement;
  let fontEvent = new CustomEvent('fonts-loaded');
  d.dispatchEvent(fontEvent);
  d.classList.add('font-loaded');
});

// Mobile nav toggle
import mobileNavToggle from './modules/nav-mobile-toggle';
new mobileNavToggle('[data-expands="nav"]');
